export class UxEvents {
    public static UxDropdownOpened = 'ux-dropdown:opened';
    public static UxDropdownOptionClicked = 'ux-dropdown-option:clicked';
    public static UxComboboxOpened = 'ux-combobox:opened';
    public static UxComboboxCleared = 'ux-combobox:cleared';
    public static UxComboboxSearch = 'ux-combobox:search';
    public static UxComboboxOptionClicked = 'ux-combobox-option:clicked';
    public static UxComboboxOptionHovered = 'ux-combobox-option:hovered';
    public static UxSelectOpened = 'ux-select:opened';
    public static UxSelectCleared = 'ux-select:cleared';
    public static UxSelectOptionClicked = 'ux-select-option:clicked';
    public static UxSelectOptionHovered = 'ux-select-option:hovered';
    public static UxDatepickerOpened = 'ux-datepicker:opened';
    public static UxRadioChanged = 'ux-radio:changed';
    public static OnSearch = 'onsearch';
    public static OnClear = 'onclear';
    public static OnSelect = 'onselect';
    public static OnChange = 'onchange';
    public static OnBlur = 'onblur';
    public static OnFocus = 'onfocus';
    public static OnInput = 'oninput';
    public static OnKeyup = 'onkeyup';
    public static OnKeydown = 'onkeydown';
}
