import { StorageKeys, getFromStorage, isFunction } from '@wecore/sdk-utilities';
import Aurelia, { Registration } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { AuthenticationService } from '../services/service.authentication';

export function registerSdk(
    aurelia: Aurelia,
    endpoint: string,
    apiVersion: string,
    auth: AuthenticationService,
    clients: any[],
    beforeRequest?: (options: RequestInit) => Promise<RequestInit>,
    afterRequest?: (url: string, response: Response) => Promise<Response>,
    shouldValidateToken: boolean = true
): void {
    const config = aurelia.container.get(AureliaConfiguration);
    aurelia.register(
        clients.map((t) =>
            Registration.callback(t, () => {
                return new t(
                    {
                        accessToken: getToken,
                        apiVersion,
                        // Make sure we have a valid token!
                        beforeRequest: async (options: RequestInit): Promise<RequestInit> => {
                            if (isFunction(beforeRequest)) options = await beforeRequest(options);
                            return shouldValidateToken ? validateToken(config, options, auth) : options;
                        },
                        afterRequest: async (url: string, response: Response): Promise<Response> => {
                            if (isFunction(afterRequest)) return afterRequest(url, response);
                            return response;
                        }
                    },
                    endpoint
                );
            })
        )
    );
}

function getToken(): Promise<string> {
    const token = getFromStorage<string>(StorageKeys.accessToken);
    return Promise.resolve(token);
}

async function validateToken(config: AureliaConfiguration, options: RequestInit, auth: AuthenticationService): Promise<RequestInit> {
    // If the user is still authenticated, just continue with the request.
    if (auth.isAuthenticated()) return options;
    // Lets see if we can refresh the token automatically.
    const succeeded = await auth.checkSession(false);
    // If we have a new token update the authorization token with the new token.
    if (succeeded) options.headers = { ...options.headers, authorization: `bearer ${await getToken()}` };
    // else {
    //     // Force the page to change to the login screen
    //     // with the current workspace, if available, included.
    //     const workspace = getFromStorage<string>(StorageKeys.workspace);
    //     if (isNotEmpty(workspace)) window.location.href = `/${workspace}`;
    // }
    // Continue the request if we can.
    return options;
}
