import * as iziToast_default from 'izitoast';
import { NotificationOptions } from '../models/notification-options';
const iziToast = iziToast_default.default || (iziToast_default as any);

export class NotificationService {
    private assetsUrl = '/static/icons';

    public async show(title: string, message: string, options?: NotificationOptions): Promise<void> {
        const merged = {
            type: 'info',
            position: 'topRight',
            duration: 4000,
            canDismiss: true,
            ...options
        } as NotificationOptions;

        switch (options.type) {
            case 'success':
                this.showSuccess(title, message, merged);
                break;
            case 'danger':
                this.showDanger(title, message, merged);
                break;
            case 'warning':
                this.showWarning(title, message, merged);
                break;
            case 'info':
            default:
                this.showInfo(title, message, merged);
                break;
        }
    }

    private showSuccess(title: string, message: string, options?: NotificationOptions): void {
        iziToast.show({
            title: title || '',
            titleColor: '#166534',
            message: message || '',
            messageColor: '#166534',
            backgroundColor: '#dcfce7',
            iconUrl: `${this.assetsUrl}/success.svg`,
            maxWidth: 500,
            position: options.position,
            timeout: options.duration,
            progressBarColor: '#16a34a',
            transitionIn: 'fadeInTop',
            transitionOut: 'fadeOutDown',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            close: options.canDismiss
        });
    }

    private showInfo(title: string, message: string, options?: NotificationOptions): void {
        iziToast.show({
            title: title || '',
            titleColor: '#1e40af',
            message: message || '',
            messageColor: '#1e40af',
            backgroundColor: '#dbeafe',
            iconUrl: `${this.assetsUrl}/info.svg`,
            maxWidth: 500,
            position: options.position,
            timeout: options.duration,
            progressBarColor: '#2563eb',
            transitionIn: 'fadeInTop',
            transitionOut: 'fadeOutDown',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            close: options.canDismiss
        });
    }

    private showDanger(title: string, message: string, options?: NotificationOptions): void {
        iziToast.show({
            title: title || '',
            titleColor: '#991b1b',
            message: message || '',
            messageColor: '#991b1b',
            backgroundColor: '#fee2e2',
            iconUrl: `${this.assetsUrl}/danger.svg`,
            maxWidth: 500,
            position: options.position,
            timeout: options.duration,
            progressBarColor: '#dc2626',
            transitionIn: 'fadeInTop',
            transitionOut: 'fadeOutDown',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            close: options.canDismiss
        });
    }

    private showWarning(title: string, message: string, options?: NotificationOptions): void {
        iziToast.show({
            title: title || '',
            titleColor: '#854d0e',
            message: message || '',
            messageColor: '#854d0e',
            backgroundColor: '#fef9c3',
            iconUrl: `${this.assetsUrl}/warning.svg`,
            maxWidth: 500,
            position: options.position,
            timeout: options.duration,
            progressBarColor: '#ca8a04',
            transitionIn: 'fadeInTop',
            transitionOut: 'fadeOutDown',
            transitionInMobile: 'fadeInUp',
            transitionOutMobile: 'fadeOutDown',
            close: options.canDismiss
        });
    }

    // id: null,
    // class: '',
    // title: title,
    // titleColor: '',
    // titleSize: '',
    // titleLineHeight: '',
    // message: message,
    // messageColor: '',
    // messageSize: '',
    // messageLineHeight: '',
    // backgroundColor: '',
    // theme: 'light', // dark
    // color: '', // blue, red, green, yellow
    // icon: '',
    // iconText: '',
    // iconColor: '',
    // iconUrl: null,
    // image: '',
    // imageWidth: 50,
    // maxWidth: null,
    // zindex: null,
    // layout: 1,
    // balloon: false,
    // close: true,
    // closeOnEscape: false,
    // closeOnClick: false,
    // displayMode: 0, // once, replace
    // position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
    // target: '',
    // targetFirst: true,
    // timeout: 5000,
    // rtl: false,
    // animateInside: true,
    // drag: true,
    // pauseOnHover: true,
    // resetOnHover: false,
    // progressBar: true,
    // progressBarColor: '',
    // progressBarEasing: 'linear',
    // overlay: false,
    // overlayClose: false,
    // overlayColor: 'rgba(0, 0, 0, 0.6)',
    // transitionIn: 'fadeInUp',
    // transitionOut: 'fadeOut',
    // transitionInMobile: 'fadeInUp',
    // transitionOutMobile: 'fadeOutDown',
    // buttons: {},
    // inputs: {},
    // onOpening: function () {},
    // onOpened: function () {},
    // onClosing: function () {},
    // onClosed: function () {}
}
