import { I18N } from '@aurelia/i18n';
import { IRoute, IRouter, RouterNavigationStartEvent } from '@aurelia/router';
import { IDisposable, IEventAggregator, inject } from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import { appVersion } from './infra/app-version';
import { ErrorHandler } from './infra/error-handler';

@inject(I18N, ErrorHandler, AureliaConfiguration, IRouter, IEventAggregator)
export class MyApp {
    public route: string = 'home';
    public lang: 'nl' | 'en' = 'nl';
    private subscriptions: IDisposable[] = [];

    static routes: IRoute[] = [
        {
            id: 'home',
            path: '',
            component: () => import('./views/page-home/page-home'),
            title: ''
        } as IRoute,
        {
            id: 'contact',
            path: 'contact',
            component: () => import('./views/page-contact/page-contact'),
            title: 'Contact / Nieuwsbrief'
        } as IRoute,
        {
            path: '*',
            component: () => import('./views/page-not-found/page-not-found')
        } as IRoute
    ];

    constructor(
        private readonly t: I18N, //
        private readonly errorHandler: ErrorHandler,
        private readonly config: AureliaConfiguration,
        private readonly router: IRouter, //
        private readonly events: IEventAggregator
    ) {}

    public async attached(): Promise<void> {
        await this.errorHandler.configure({
            environment: this.config.get('environment.name'),
            accessToken: this.config.get('rollbar.accessToken'),
            enabled: this.config.get('rollbar.enabled'),
            version: appVersion(),
            payload: {}
        });

        this.subscriptions = [
            this.events.subscribe('au:router:navigation-start', (payload: RouterNavigationStartEvent) => {
                this.route = payload.navigation.instruction as string;

                console.log(this.route);
            })
        ];
    }

    public async setLanguage(lang: 'nl' | 'en') {
        this.lang = lang;
        await this.t.setLocale(lang);
        this.events.publish('languages:changed', lang);
    }

    public detaching(): void {
        this.subscriptions.forEach((s) => s.dispose());
    }
}
