import { DialogDefaultConfiguration } from '@aurelia/dialog';
import { I18nConfiguration } from '@aurelia/i18n';
import { RouterConfiguration } from '@aurelia/router';
import splitbee from '@splitbee/web';
import { MailjetApiClient } from '@wecore/sdk-integrations';
import Aurelia from 'aurelia';
import { AureliaConfiguration } from 'aurelia-configuration';
import Fetch from 'i18next-fetch-backend';
import { registerSdk } from './infra/utilities';
import { MyApp } from './my-app';
import { AuthenticationService } from './services/service.authentication';
import { UxBadge } from './ux/ux-badge/ux-badge';
import { UxButton } from './ux/ux-button/ux-button';
import { UxInput } from './ux/ux-input/ux-input';

const aurelia = Aurelia.register(
    RouterConfiguration.customize({
        useUrlFragmentHash: false,
        useHref: false,
        title: '${componentTitles}${appTitleSeparator}Wezorg',
        navigationSyncStates: ['guardedUnload', 'swapped', 'completed', 'guardedLoad']
    })
)
    .register(
        I18nConfiguration.customize((options) => {
            options.initOptions = {
                plugins: [Fetch],
                backend: {
                    loadPath: '/locales/{{lng}}/{{ns}}.json'
                },
                lng: 'nl',
                fallbackLng: 'en',
                debug: false
            };
        })
    )
    .register(
        DialogDefaultConfiguration.customize((settings) => {
            settings.lock = true;
            settings.startingZIndex = 9999;
        })
    )
    .register([
        UxInput, //
        UxBadge,
        UxButton
    ]);

const config = aurelia.container.get(AureliaConfiguration);
await config.loadConfig();
const auth = aurelia.container.get(AuthenticationService);

splitbee.init({ token: config.get('splitbee.token') });

registerSdk(aurelia, config.get('api.integrations'), '1.0.0', auth, [MailjetApiClient], undefined, undefined, false);

aurelia.app(MyApp).start();
